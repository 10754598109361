



























































































































































































































































































































































































































































































































.q-item
  min-height initial
  .q-item__section
    min-width initial
    color initial
